import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  TriggeredByOptions,
  WidgetComponents,
  WidgetElements,
} from '../../../../utils/bi/consts';
import { SetSelectedTime } from '../setSelectedTime/setSelectedTime';
import { isSlotWithOpenWaitingList } from '../../../../utils/timeSlots/timeSlots';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { FlowElements } from '../../Hooks/useFlow';
import { SetFocusedElement } from '../setFocusedElement/setFocusedElement';

export type OnTimeSelected = (selectedTime: string) => void;

export function createOnTimeSelectedAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setSelectedTimeAction: SetSelectedTime,
  setFocusedElement: SetFocusedElement,
): OnTimeSelected {
  return async (selectedTime: string) => {
    const { biLogger } = context;
    const [state] = getControllerState();
    const { availableSlots } = state;

    setSelectedTimeAction(selectedTime);

    const isSelectedTimeSlotWithWaitingListIndication = isAtLeastOneSlotInSelectedTimeIsWithWaitingList(
      selectedTime,
      availableSlots?.availabilityEntries!,
    );
    void biLogger.bookingsCalendarClick({
      element: WidgetElements.TIME_SLOT,
      component: WidgetComponents.TIME_PICKER,
      properties: JSON.stringify({
        selectedTime,
        ...(isSelectedTimeSlotWithWaitingListIndication
          ? { waitlist: true }
          : {}),
      }),
    });
    void biLogger.bookingsCalendarBookingDetailsLoad({
      triggeredBy: TriggeredByOptions.TIME_SELECTED,
      selectedSlot: selectedTime,
    });

    setFocusedElement(FlowElements.BOOKING_DETAILS);
  };
}

const isAtLeastOneSlotInSelectedTimeIsWithWaitingList = (
  selectedTime: string,
  availableSlots: SlotAvailability[],
): boolean => {
  return availableSlots.reduce(
    (accumulator: boolean, slot: SlotAvailability) =>
      (slot.slot?.start === selectedTime && isSlotWithOpenWaitingList(slot)) ||
      accumulator,
    false,
  );
};
