import { Service, ServiceLocationType } from '@wix/bookings-uou-types';
import { CalendarState } from '../../components/BookingCalendar/controller';
import { BottomSectionStatus } from '../../components/BookingCalendar/ViewModel/widgetViewModel/widgetViewModel';
import { getLocalTimezone } from '../dateAndTime/dateAndTime';
import { TimePickerStatus } from '../../components/BookingCalendar/ViewModel/timePickerViewModel/timePickerViewModel';
import { FilterTypes } from '../../components/BookingCalendar/ViewModel/filterViewModel/filterViewModel';
import {
  WixOOISDKAdapter,
  BookingsQueryParams,
} from '@wix/bookings-adapter-ooi-wix-sdk';

export function createInitialState(
  service: Service,
  wixSdkAdapter: WixOOISDKAdapter,
): CalendarState {
  let locationFilterOptions: string[] = [];
  const locationQueryParam = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.LOCATION,
  );
  if (locationQueryParam) {
    if (Array.isArray(locationQueryParam)) {
      locationFilterOptions = locationQueryParam;
    } else {
      locationFilterOptions.push(locationQueryParam);
    }
  } else {
    locationFilterOptions = service.locations
      .filter(({ type }) => type === ServiceLocationType.OWNER_BUSINESS)
      .map(({ businessLocation }) => businessLocation?.id ?? '');
  }

  let staffFilterOptions: string[] = [];
  const staffQueryParam = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.STAFF,
  );
  if (staffQueryParam) {
    if (Array.isArray(staffQueryParam)) {
      staffFilterOptions = staffQueryParam;
    } else {
      staffFilterOptions.push(staffQueryParam);
    }
  } else {
    staffFilterOptions = service.staffMembers.map(({ id }) => id);
  }

  return {
    bottomSectionStatus: BottomSectionStatus.LOADING,
    timePickerStatus: TimePickerStatus.LOADING,
    selectedService: service,
    selectedDate: undefined,
    selectedTimezone: getLocalTimezone(),
    selectedTime: undefined,
    availableSlots: undefined,
    bookableSlotsAtSelectedTime: undefined,
    selectedMonth: undefined,
    dateAvailability: undefined,
    selectedBookingPreferences: [],
    calendarErrors: [],
    dialog: undefined,
    filterOptions: {
      [FilterTypes.LOCATION]: locationFilterOptions,
      [FilterTypes.STAFF_MEMBER]: staffFilterOptions,
      [FilterTypes.AVAILABLE_SPOTS]: ['1'],
    },
  };
}
