import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { SetCalendarErrors } from '../setCalendarErrors/setCalendarErrors';
import {
  Preference,
  SetError,
  TriggeredByOptions,
} from '../../../../utils/bi/consts';
import { getBookingPreferencesForSelectedTime } from '../../../../utils/bookingPreferences/bookingPreferencesForSelectedTime';
import {
  BookingPreference,
  SelectedBookingPreference,
} from '../../../../utils/bookingPreferences/bookingPreferences';

export type OnBookingPreferenceOptionSelected = (
  selectedSlotOption: SelectedBookingPreference,
) => Promise<void>;

export function createOnBookingPreferenceOptionSelectedAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setCalendarErrorsAction: SetCalendarErrors,
): OnBookingPreferenceOptionSelected {
  return async (selectedBookingPreference: SelectedBookingPreference) => {
    const [state, setState] = getControllerState();
    const { biLogger, settings, t, businessInfo } = context;
    const {
      selectedBookingPreferences: prevSelectedBookingPreferences,
      selectedTime,
      bookableSlotsAtSelectedTime,
      calendarErrors,
    } = state;

    if (bookableSlotsAtSelectedTime) {
      const dateRegionalSettingsLocale = businessInfo.dateRegionalSettingsLocale!;
      const bookingPreferences: BookingPreference[] = getBookingPreferencesForSelectedTime(
        {
          bookableSlotsAtSelectedTime,
          calendarErrors,
          t,
          settings,
          dateRegionalSettingsLocale,
          selectedBookingPreferences: prevSelectedBookingPreferences,
        },
      );

      const selectedPreference = bookingPreferences.filter(
        (preference: BookingPreference) =>
          preference.key === selectedBookingPreference.key,
      );
      const preferenceError =
        selectedPreference &&
        selectedPreference.length > 0 &&
        selectedPreference[0].error.key;

      if (preferenceError) {
        setCalendarErrorsAction(preferenceError, SetError.REMOVE);
      }

      const previouslySelectedPreferencesOnlyFromOtherPreferences = prevSelectedBookingPreferences.filter(
        (prePreference) => prePreference.key !== selectedBookingPreference.key,
      );
      const selectedBookingPreferences = [
        ...previouslySelectedPreferencesOnlyFromOtherPreferences,
        selectedBookingPreference,
      ];

      setState({
        selectedBookingPreferences,
      });

      void biLogger.bookingsCalendarBookingDetailsLoad({
        triggeredBy: getPreferenceTriggeredBy(selectedBookingPreference.key),
        selectedSlot: selectedTime,
        properties: JSON.stringify(selectedBookingPreference),
      });
    }
  };
}

const getPreferenceTriggeredBy = (preference: Preference) => {
  switch (preference) {
    case Preference.STAFF_MEMBER:
      return TriggeredByOptions.BOOKING_DETAILS_STAFF_MEMBER_SELECTABLE;
    case Preference.LOCATION:
      return TriggeredByOptions.BOOKING_DETAILS_LOCATION_SELECTABLE;
    case Preference.DURATION:
      return TriggeredByOptions.BOOKING_DETAILS_DURATION_SELECTABLE;
    default:
      return '';
  }
};
