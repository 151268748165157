import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';
import { CalendarState } from '../../controller';

export type OnDialogClose = () => void;

export function createOnDialogCloseAction({
  getControllerState,
  context: { biLogger },
}: ActionFactoryParams<CalendarState, CalendarContext>): OnDialogClose {
  return () => {
    const [, setState] = getControllerState();

    biLogger.bookingsCalendarClick({
      component: WidgetComponents.RESCHEDULE_DIALOG,
      element: WidgetElements.CLOSE_BUTTON,
    });

    setState({
      dialog: undefined,
    });
  };
}
