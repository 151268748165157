import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { TFunction } from '../../components/BookingCalendar/controller';
import { CalendarErrors } from '../bi/consts';
import { CalendarContext } from '../context/contextFactory';
import {
  getBookingPreferences,
  BookingPreference,
  BookingPreferenceOption,
  SelectedBookingPreference,
} from './bookingPreferences';

export const getBookingPreferencesForSelectedTime = ({
  bookableSlotsAtSelectedTime,
  selectedBookingPreferences,
  calendarErrors,
  t,
  settings,
  dateRegionalSettingsLocale,
}: {
  bookableSlotsAtSelectedTime: SlotAvailability[];
  selectedBookingPreferences?: SelectedBookingPreference[];
  calendarErrors: CalendarErrors[];
  t: TFunction;
  dateRegionalSettingsLocale: string;
  settings: CalendarContext['settings'];
}): BookingPreference[] => {
  const bookingPreferences = getBookingPreferences({
    dateRegionalSettingsLocale,
    settings,
    t,
  });
  return createBookingPreferencesForSelectedTime({
    bookingPreferences,
    bookableSlotsAtSelectedTime,
    selectedBookingPreferences,
    calendarErrors,
  });
};

const createBookingPreferencesForSelectedTime = ({
  bookingPreferences,
  bookableSlotsAtSelectedTime,
  selectedBookingPreferences,
  calendarErrors,
}: {
  bookingPreferences: BookingPreference[];
  bookableSlotsAtSelectedTime: SlotAvailability[];
  selectedBookingPreferences?: SelectedBookingPreference[];
  calendarErrors: CalendarErrors[];
}): BookingPreference[] => {
  return bookingPreferences.map((someBookingPreference: BookingPreference) => {
    const { key, error, placeholder } = someBookingPreference;
    const hasError = calendarErrors.includes(error.key);
    const options = createOptionsForBookingPreference({
      bookableSlotsAtSelectedTime,
      bookingPreferences,
      selectedBookingPreferences,
      someBookingPreference,
    });

    return {
      key,
      options,
      error: {
        key: error.key,
        message: hasError ? error.message : '',
      },
      placeholder,
    };
  });
};

const createOptionsForBookingPreference = ({
  bookableSlotsAtSelectedTime,
  bookingPreferences,
  selectedBookingPreferences,
  someBookingPreference,
}: {
  bookableSlotsAtSelectedTime: SlotAvailability[];
  bookingPreferences: BookingPreference[];
  selectedBookingPreferences?: SelectedBookingPreference[];
  someBookingPreference: BookingPreference;
}): BookingPreferenceOption[] => {
  const filteredBookableSlots: SlotAvailability[] = filterBookableSlotsAccordingToSelectedPreferences(
    {
      bookableSlotsAtSelectedTime,
      bookingPreferences,
      selectedBookingPreferences,
      someBookingPreference,
    },
  );

  const bookingPreferenceOptions: BookingPreferenceOption[] = [];
  bookableSlotsAtSelectedTime.forEach((bookableSlots) => {
    const slotBookingPreferenceOption = someBookingPreference.getBookingPreferenceOptionFromSlot!(
      bookableSlots,
    );
    const isSelectable = isBookingPreferenceOptionSelectable({
      bookingPreferenceOptions,
      filteredBookableSlots,
      someBookingPreference,
      slotBookingPreferenceOption,
    });

    bookingPreferenceOptions.push({
      ...slotBookingPreferenceOption,
      isSelectable,
    });
  });

  return removeDuplicatePreferenceOptions(bookingPreferenceOptions);
};

const removeDuplicatePreferenceOptions = (
  bookingPreferenceOptions: BookingPreferenceOption[],
): BookingPreferenceOption[] => {
  const uniqueBookingPreferenceOptions: BookingPreferenceOption[] = [];

  bookingPreferenceOptions.forEach((bookingPreferenceOption) => {
    const uniqueBookingPreferenceOptionWithTheSameId = uniqueBookingPreferenceOptions.find(
      (uniqueBookingPreferenceOption: BookingPreferenceOption) =>
        bookingPreferenceOption.id === uniqueBookingPreferenceOption.id,
    );

    const isBookingPreferenceOptionNotExists = !uniqueBookingPreferenceOptionWithTheSameId;
    if (isBookingPreferenceOptionNotExists) {
      uniqueBookingPreferenceOptions.push(bookingPreferenceOption);
    }
  });

  return uniqueBookingPreferenceOptions;
};

const filterBookableSlotsAccordingToSelectedPreferences = ({
  bookableSlotsAtSelectedTime,
  bookingPreferences,
  selectedBookingPreferences,
  someBookingPreference,
}: {
  bookableSlotsAtSelectedTime: SlotAvailability[];
  bookingPreferences: BookingPreference[];
  selectedBookingPreferences?: SelectedBookingPreference[];
  someBookingPreference: BookingPreference;
}): SlotAvailability[] => {
  let filteredBookableSlotsAtSelectedTime = bookableSlotsAtSelectedTime;
  bookingPreferences.forEach((preference: BookingPreference) => {
    const selectedBookingPreference = selectedBookingPreferences?.find(
      (selectedPreference) => preference.key === selectedPreference.key,
    );

    if (
      selectedBookingPreference &&
      preference.key !== someBookingPreference.key
    ) {
      filteredBookableSlotsAtSelectedTime = filteredBookableSlotsAtSelectedTime.filter(
        (bookableSlot) =>
          preference.getBookingPreferenceOptionFromSlot!(bookableSlot).id ===
          selectedBookingPreference.value,
      );
    }
  });
  return filteredBookableSlotsAtSelectedTime;
};

const isBookingPreferenceOptionSelectable = ({
  bookingPreferenceOptions,
  filteredBookableSlots,
  someBookingPreference,
  slotBookingPreferenceOption,
}: {
  bookingPreferenceOptions: BookingPreferenceOption[];
  filteredBookableSlots: SlotAvailability[];
  someBookingPreference: BookingPreference;
  slotBookingPreferenceOption: BookingPreferenceOption;
}) => {
  const isSlotBookingPreferenceSelected = bookingPreferenceOptions.find(
    (bookingPreferenceOption: BookingPreferenceOption) =>
      bookingPreferenceOption.id === slotBookingPreferenceOption.id,
  );
  if (!isSlotBookingPreferenceSelected) {
    const isOptionSelectable = filteredBookableSlots.some(
      (filteredSlot: SlotAvailability) => {
        return (
          someBookingPreference.getBookingPreferenceOptionFromSlot!(
            filteredSlot,
          ).id === slotBookingPreferenceOption.id
        );
      },
    );
    return isOptionSelectable;
  }
  return false;
};
