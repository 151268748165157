import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';
import { CalendarState } from '../../controller';
import { OnDialogClose } from '../onDialogClose/onDialogClose';

export type OnDialogConfirm = () => void;

export function createOnDialogConfirmAction(
  {
    getControllerState,
    context: { biLogger },
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  onDialogClose: OnDialogClose,
): OnDialogConfirm {
  biLogger.bookingsCalendarClick({
    component: WidgetComponents.RESCHEDULE_DIALOG,
    element: WidgetElements.CONFIRM_BUTTON,
  });

  return () => {
    onDialogClose();
  };
}
