import { Booking } from '@wix/ambassador-bookings-server/types';
import { CatalogData } from '@wix/bookings-uou-types';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { QueryAvailabilityResponse } from '@wix/ambassador-availability-calendar/types';
import { mockGetSlotsForSelectedDate } from '../../__tests__/mocks/ambassador/mockGetSlotsForSelectedDate';
import { getLocalDateTimeStartOfDay } from '../utils/dateAndTime/dateAndTime';
import { createDummyCatalogData } from './dummyData/dummyCatalogData';
import { BookingsApi } from './BookingsApi';
import { CalendarApiInitParams, SlotsAvailabilityFilter } from './types';
import { Optional } from '../types/types';
import { PaidPlans } from '@wix/ambassador-checkout-server/types';

export const CALENDAR_PAGE_URL_PATH_PARAM = 'booking-calendar';

export class CalendarApi {
  private wixSdkAdapter: WixOOISDKAdapter;
  private bookingsApi: BookingsApi;

  constructor({ wixSdkAdapter }: CalendarApiInitParams) {
    this.wixSdkAdapter = wixSdkAdapter;
    this.bookingsApi = new BookingsApi({
      authorization: wixSdkAdapter.getInstance(),
      baseUrl: wixSdkAdapter.getServerBaseUrl(),
    });
  }

  async getCatalogData(): Promise<CatalogData> {
    if (this.wixSdkAdapter.isEditorMode()) {
      return createDummyCatalogData();
    }

    const serviceSlug = await this.wixSdkAdapter.getServiceSlug(
      CALENDAR_PAGE_URL_PATH_PARAM,
    );

    return this.bookingsApi.getCatalogData({ serviceSlug });
  }

  async getNextAvailableDate(slotsAvailabilityFilter: SlotsAvailabilityFilter) {
    const dateAvailabilityResponse = await this.getDateAvailability(
      slotsAvailabilityFilter,
    );
    const nextAvailableDate = dateAvailabilityResponse.nextAvailable?.date;

    return nextAvailableDate && getLocalDateTimeStartOfDay(nextAvailableDate);
  }

  getDateAvailability(slotsAvailabilityFilter: SlotsAvailabilityFilter) {
    // prepare slot availability filter : {from, to: from + end of month, timezone}
    return this.bookingsApi.getDateAvailability(slotsAvailabilityFilter);
  }

  async getSlotsForSelectedDate(
    slotsAvailabilityFilter: SlotsAvailabilityFilter,
  ): Promise<QueryAvailabilityResponse> {
    if (this.wixSdkAdapter.isEditorMode()) {
      const { from } = slotsAvailabilityFilter;
      return mockGetSlotsForSelectedDate(from);
    }

    return this.bookingsApi.getSlotsForSelectedDate(slotsAvailabilityFilter);
  }

  async getBookingDetails(bookingId: string): Promise<Optional<Booking>> {
    return this.bookingsApi.getBookingDetails(bookingId);
  }

  async getPricingPlanDetails(
    rfcStartTime: string,
    serviceId: string,
  ): Promise<Optional<PaidPlans>> {
    return this.bookingsApi.getPricingPlanDetails(rfcStartTime, serviceId);
  }
}
