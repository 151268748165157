import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { ServiceLocationType } from '@wix/bookings-uou-types';

export enum FilterTypes {
  LOCATION = 'LOCATION',
  STAFF_MEMBER = 'STAFF_MEMBER',
  AVAILABLE_SPOTS = 'AVAILABLE_SPOTS',
}

export type FilterOption = {
  label: string;
  value: string;
  selected: boolean;
};

export type FilterViewModel = {
  id: FilterTypes;
  label: string;
  options: FilterOption[];
  isMultiSelect: boolean;
  note?: string;
};

export function createFilterViewModels({
  state,
  context,
}: ViewModelFactoryParams<CalendarState, CalendarContext>): FilterViewModel[] {
  const { t } = context;
  const { selectedService, filterOptions } = state;
  const availableLocations = selectedService.locations;
  const availableStaffMembers = selectedService.staffMembers;
  const filterViewModels: FilterViewModel[] = [];

  if (availableLocations.length > 1) {
    const selectedLocationsOptions = filterOptions.LOCATION;
    filterViewModels.push({
      label: t('filter.location.label'),
      options: availableLocations
        .filter(({ type }) => type === ServiceLocationType.OWNER_BUSINESS)
        .map(({ businessLocation }) => ({
          selected: selectedLocationsOptions.some(
            (selectedLocationsOption) =>
              selectedLocationsOption === businessLocation?.id,
          ),
          label: businessLocation?.name || '',
          value: businessLocation?.id || '',
        })),
      isMultiSelect: true,
      id: FilterTypes.LOCATION,
    });
  }

  if (availableStaffMembers.length > 1) {
    const selectedStaffMembersOptions = filterOptions.STAFF_MEMBER;
    filterViewModels.push({
      label: t('filter.staff-member.label'),
      options: availableStaffMembers.map(({ id, name }) => ({
        selected: selectedStaffMembersOptions.some(
          (selectedStaffMembersOption) => selectedStaffMembersOption === id,
        ),
        label: name,
        value: id,
      })),
      isMultiSelect: true,
      id: FilterTypes.STAFF_MEMBER,
    });
  }

  if (selectedService.policy.capacity > 1) {
    const selectedAvailableSpotsOption = filterOptions.AVAILABLE_SPOTS[0];
    const maxParticipantsPerBook =
      selectedService.policy.maxParticipantsPerBook;
    filterViewModels.push({
      label: t('filter.available-spots.label'),
      options: new Array(selectedService.policy.capacity)
        .fill(null)
        .map((__, index) => {
          const amount = String(index + 1);
          return {
            selected: selectedAvailableSpotsOption === amount,
            label: amount,
            value: amount,
          };
        }),
      isMultiSelect: false,
      id: FilterTypes.AVAILABLE_SPOTS,
      note:
        Number(selectedAvailableSpotsOption) > maxParticipantsPerBook
          ? t('filter.number-of-participants.note', { maxParticipantsPerBook })
          : undefined,
    });
  }

  return filterViewModels;
}
