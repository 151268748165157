import { TimezoneType } from '@wix/bookings-uou-types';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { TriggeredByOptions } from '../../../../utils/bi/consts';
import {
  getLocalTimezone,
  getTodayLocalDateTimeStartOfDay,
} from '../../../../utils/dateAndTime/dateAndTime';
import { BottomSectionStatus } from '../../ViewModel/widgetViewModel/widgetViewModel';
import { SetSelectedMonth } from '../setSelectedMonth/setSelectedMonth';
import { CalendarState } from '../../controller';
import { BookingsQueryParams } from '@wix/bookings-adapter-ooi-wix-sdk';
import { SetSelectedDate } from '../setSelectedDate/setSelectedDate';

export type InitializeWidget = () => Promise<void>;

export function createInitializeWidgetAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setSelectedDate: SetSelectedDate,
  setSelectedMonth: SetSelectedMonth,
): InitializeWidget {
  return async () => {
    const { wixSdkAdapter, biLogger, businessInfo } = context;

    if (wixSdkAdapter.isSSR()) {
      return;
    }

    const [, setState] = getControllerState();

    const selectedTimezone =
      businessInfo.timezoneProperties?.defaultTimezone === TimezoneType.CLIENT
        ? getLocalTimezone()
        : businessInfo.timeZone!;
    setState({ selectedTimezone });

    const todayLocalDateTime = getTodayLocalDateTimeStartOfDay(
      selectedTimezone,
    );

    const [rescheduleBookingDetails] = await Promise.all([
      getBookingDetails(context),
      setSelectedDate(todayLocalDateTime, TriggeredByOptions.INITIALIZE_WIDGET),
      setSelectedMonth(
        todayLocalDateTime,
        TriggeredByOptions.INITIALIZE_WIDGET,
      ),
    ]);
    setState({ bottomSectionStatus: BottomSectionStatus.LOADED });

    if (rescheduleBookingDetails) {
      setState({ rescheduleBookingDetails });
    }

    await biLogger.bookingsCalendarPageLoaded({});

    await biLogger.bookingsCalendarBookingDetailsLoad({
      triggeredBy: TriggeredByOptions.INITIALIZE_WIDGET,
    });

    // TODO handle empty state
    // setState({ widgetStatus: WidgetStatus.NO_SERVICE_AVAILABILITY });
    // void biLogger.bookingsCalendarPageLoaded({
    //    errorMessage: ErrorMessages.noServiceAvailability,
    //  });
  };
}

async function getBookingDetails(context: CalendarContext) {
  const bookingId = context.wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.BOOKING_ID,
  );

  if (!bookingId) {
    return;
  }

  const bookingDetails = await context.calendarApi.getBookingDetails(bookingId);

  return bookingDetails;
}
